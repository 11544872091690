<template>
    <div class="js-entry o-entry c-contact-entry">
        <h3 class="c-contact-entry__name">
            {{entryData.Json.Name}}
        </h3>
        <dl class="c-contact-entry__details">
            <dt v-if="entryData.Json.Phone">{{ $t("global.phone") }}</dt>
            <dd v-if="entryData.Json.Phone">{{entryData.Json.Phone}}</dd>

            <dt v-if="entryData.Json.Mobile">{{ $t("global.mobile") }}</dt>
            <dd v-if="entryData.Json.Mobile">{{entryData.Json.Mobile}}</dd>

            <dt v-if="entryData.Json.Fax">Fax</dt>
            <dd v-if="entryData.Json.Fax">{{entryData.Json.Fax}}</dd>

            <dt v-if="entryData.Json.Email">Email</dt>
            <dd v-if="entryData.Json.Email">{{entryData.Json.Email}}</dd>
        </dl>
    </div>
</template>

<script>
import entryMixin from '../../mixins/entry'

export default {
  mixins: [entryMixin]
}
</script>
